import { AnimatePresence, motion } from "framer-motion";
import { nanoid } from "nanoid";
import Modal from 'react-modal';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Button from "../../components/Button";
import { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import Nav from "../NavAdminOnly";
import { FiArrowRight } from "react-icons/fi";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { Link } from "react-router-dom";
import Footer from "../Footer";
import { GetQuestDetailsHook, GetBountyByQuestHook } from "../../hooks";
import { AcceptFileType, FileUploadService } from "../../utilities";
import DOMPurify from 'dompurify';


export default function ViewQuest() {

  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const params = useParams<{ seasonid: string, id: string }>();

  const { data: questData } = GetQuestDetailsHook(params.id);
  // replace this with bounties by quest once backend complete
  const { data: bountyData } = GetBountyByQuestHook(params.id);

  const addBounty = () => {
    navigate(`/admin/create-bounty/${params.seasonid}/${params.id}`);
  }
   
  // Add the background image for the season
  const seasonStyle = {
    backgroundImage: questData?.image
        ? `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.9)), url(${questData.secimage})`
        : 'none',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat', // this is typically included in the 'background' shorthand
  };

  return (
      <>
        <Nav/>
        <ContentWrapper>
          <StyledSeason
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{ duration: 1.5, ease: "easeInOut" }}
            key="bounty"
            style={seasonStyle} 
          >
          <A1>
            <div className="divider">
              <div className="header-row">
                <div className="season-name">
                  <h1>{questData?.title}</h1>
                </div>
                <div className="buttons">
                 <button
                    className="item-edit"
                    onClick={() => addBounty()}
                  >
                    Add Bounty <AiOutlineEdit />
                  </button>
                </div>
              </div>
            </div>
            <div className="sections">
              <h4 className="item-title">Bounties</h4>
              <div className="live-items">
                {bountyData.map((item) => (
                  <div className="live-item" key={item._id}>
                    {item.title && (
                      <p>{item.title}</p>
                    )}
                  </div> 
                ))}
              </div>
            </div>
          </A1>
      </StyledSeason>
    </ContentWrapper>
    <Footer />
  </>
  );
}



const ContentWrapper = styled.div`
  display: flex;
`;

const CustBtn = styled.button`
  display: block;
  width: 40%;
  background-color: transparent;
  color: white;
  border: 1px solid white;
  padding: 0.6rem 2rem;
  font-size: clamp(0.5rem, 0.6vw, 0.65vw);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: nowrap;
  &:hover {
    background-color: white;
    color: black;
`

const modalStyle = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: black; /* Set the background color you want */
  border: 1px solid #ccc; /* Add a border if desired */
  padding: 20px; /* Add padding as needed */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); /* Add a box shadow for depth */
  z-index: 1000; /* Ensure the modal appears above other content */
  max-width: 80%;
`;

const ModalWrapper = styled.section`
  ${modalStyle}
`;

const customOverlay = styled.section`
  background-color: rgba(0, 0, 0, 1.0); /* Set the background color and opacity for the overlay */
  z-index: 999; /* Ensure the overlay covers other content */
`

const StyledSeason = styled(motion.main)`
  background-position: top left;
  min-height: 100vh;
  margin-top: 5.4rem;
  flex: 1;

  p {
    margin: 1.5rem 0;
    width: 90%;
    max-width: 100%;
    a {
      margin: 1.5rem 0;
      font-size: 0.7rem;
      width: 100%;
      color: #ba8c70;
      text-decoration: underline;
    }
  }
`;

const StyledCreateRewardType = styled(motion.main)`
   margin-top: 5.4rem;
  flex: 1;

  .create-form {
    padding: 0rem 0rem;
    text-align: left;
    @media (max-width: 768px) {
      padding: 0;
    }
  }

  .sections {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    
    label {
      width: 100%;
    }

    .submitFormBtn {
      display: flex;
      justify-content: flex-start;
      margin-top: 2rem;
    }
  }
`;

 const A1 = styled.section`
  width: 100%;
  padding: 3rem 0 3rem 3rem;
  @media (max-width: 768px) {
    padding: 1.5rem 0 1.5rem 1.5rem;
  }

  .live-items {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 0rem 0.3rem;
      width: 100%;
    }
    .live-item {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
    }
    .item-header {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      width: 100%;
    }

    .live-item:not(:last-child) {
      border-bottom: 1px solid #ccc; /* Change color as needed */
      padding-bottom: 10px; /* Adjust padding as needed */
      margin-bottom: 10px; /* Adjust margin as needed */

    }

  .header-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .season-name {
    /* Adjust styles as needed */
  }

  .buttons {
    display: flex;
    gap: 10px; /* Adjust the gap between buttons */
  }

  .custom-dropdown {
    position: relative;
    font-size: 0.8rem;
    padding: 0.8rem 1.5rem 0.8rem 1rem;
    background: rgba(90, 90, 90, 0.36);
    border: none;
    backdrop-filter: blur(1rem);
    color: #fff;
    outline: none;
    width: 50%; /* Full width */
    margin: 0; /* Reset margin */
    appearance: none; /* Remove default appearance */
    -webkit-appearance: none; /* Remove default appearance in Safari */
  }

  .w-20 {
    width: 40%;
    padding: 10px 18px;
    font-size: 11px;
    @media (max-width: 594px) {
      width: 30%;
      font-size: 8px;
    }
  }
  .center-align {
    justify-content: center;
    width: 100%;
    display: flex;
  }

  input,
  textarea {
    position: relative;
    font-size: 0.8rem;
    padding: 0.8rem 1.5rem 0.8rem 1rem;
    background: rgba(90, 90, 90, 0.36);
    border: none;
    backdrop-filter: blur(1rem);
    color: #fff;
    outline: none;
    &::placeholder {
      color: #6f6969;
    }

    &.img-upload {
      background-image: url("/images/uploadBg.png");
      background-repeat: no-repeat;
      background-position: center;
      width: 35%;
      height: 15rem;
      &::placeholder {
        position: absolute;
        top: 35%;
        left: 39%;
        @media (max-width: 950px) {
          left: 35%;
        }
      }
    }
    &.w-9 {
      /* time */
      width: 15%;
    }
    &.w-15 {
      width: 15%;
      margin-right
      &.w-100 {
        width: 100%;
      }
      &.w-100::-webkit-input-placeholder:after {
          content:"*";
          color:#DA358E;
          }
      &.w-100::-moz-placeholder:after {
           content:"*";
          color:#DA358E;
          }
       &.w-100:-ms-input-placeholder:after {
           content:"*";
          color:#DA358E;
          }
      
      &.full-w {
        width: 100%;
        height: 100px;
        &::placeholder {
          position: absolute;
        }
      }
    }
  .divider {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      align-items: start;
      @media (max-width: 768px) {
        grid-template-columns: repeat(1, 1fr);
        padding: 0;
      }
    }
  .card-bg{
      background-color:rgba(31, 31, 31, 0.7);
  }
   .submitFormBtn {
      display: flex;
      justify-content: flex-end;
    }
  .sections {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding:0.5rem;
      h1,h2,h3,h4{
          text-transform: uppercase;
      }
    h1 {
        font-size: 1.5vw !important;
        font-size: clamp(1.2rem, 1.5vw, 1.5vw)!important;
      
      }
    h3{
      font-size: 1.3vw;
      font-size: clamp(1rem, 1.3vw, 1.3vw);
    }
      h4{
          font-size:1.0vw;
          font-size: clamp(0.7rem,1.0vw,1.0vw)
      }
      .text-center{
          text-align:center;
          width:100% !important;
      }
      .pink-text{
          color:#DA358E;
      }
     p {
        margin: 1.5rem 0;
        width:90%;
        
      }
    .omc{
      text-align:center;
           width:100px;
           height:40px;
           line-height:40px;
           border-radius:25px;
           background-color:#BA8C70;
           color:#141414
    }
      }

      .claim-term-bottom {
          transition: opacity 500ms;
          &.disabled {
            pointer-events: none;
            opacity: 0.6;
          }
          &_checkbox {
              display:-webkit-flex;
              display:-webkit-box;
              display:-moz-flex;
              display:-moz-box;
              display:-ms-flexbox;
              display:flex;
              flex-direction: row;
              flex-wrap: nowrap;
              align-content: center;
              align-items: center;
              cursor: pointer;
      
            p {
              margin-left: 20px;
            }
          }
         
          &_btn {
            text-align: center;
            button {
              margin-top:20px;
              padding: 20px 60px;
              border: 1px solid;
            }
          }
        }
        
  `;

  const NullState = styled.section`
    .a1 {
      min-height: 50vh;
      padding: 0 3rem;
    }
    .a2 {
      margin: 0 0 6rem;
    }
  `;