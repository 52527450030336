import { BaseService } from './base'
import { APIPath, IUserBounty } from '../constant'

class UserBounty {

    create(data: IUserBounty): Promise<IUserBounty> {
      const storedToken = localStorage.getItem('token') || ''; // Use an empty string as a default value
      return BaseService.postDataWithToken<IUserBounty>(APIPath.userbounty, data, storedToken);
    }
    
    getBountiesByID(id: string): Promise<IUserBounty[]> {
        //console.log('the path is', `${APIPath.userbounty}/${id}`);
        return BaseService.get(`${APIPath.userbounty}/${id}`)
    }

    getBountiesBySeason(id: string): Promise<IUserBounty[]> {
        //console.log('the path is', `${APIPath.userbounty}/${id}`);
        return BaseService.get(`${APIPath.userbounty}/${id}`)
    }

    getBountyByID(id: string): Promise<IUserBounty> {
        return BaseService.get(`${APIPath.userbounty}/${id}`)
    }

    getAllUserBounty(): Promise<IUserBounty[]> {
        //console.log('the path is', `${APIPath.userbounty}`);
        return BaseService.get(`${APIPath.userbounty}`)
    }

  getApprovedUserBounties(userID: string): Promise<IUserBounty[]> {
    const storedToken = localStorage.getItem('token');
    if (!storedToken) {
      throw new Error('Token not found in localStorage');
    }
    if (!userID) {
      return Promise.resolve([]); // Return an empty array if userID is falsy
    }
    return BaseService.getWithToken(`${APIPath.userbounty}/approved/${userID}`, storedToken);
  }

    rejectuserBountyByID(id: string, data: IUserBounty): Promise<IUserBounty> {
        return BaseService.put(`${APIPath.userbounty}/reject/${id}`, data)
    }
    acceptuserBountyByID(id: string, data: IUserBounty): Promise<IUserBounty> {
        return BaseService.put(`${APIPath.userbounty}/accept/${id}`, data)
    }
}

const UserBountyService = new UserBounty()
Object.freeze(UserBountyService)
export { UserBountyService }
