import { motion } from "framer-motion";
import { useState, useEffect } from 'react';
import styled from "styled-components";
import Nav from "../NavAdminOnly";
import Footer from "../Footer";
import { IBounty, IUserBounty, UserBountyService, EndUserService } from "../../utilities";
import { GetBountyListHook, GetSeasonListHook } from "../../hooks";

interface IUserBountyExtended extends IUserBounty {
  bountyTitle?: string; 
  discordID?: string;
  twitterID?: string;
  bounty_id?: string;
}

export default function BountySubmissionList() {
  
  const [isAvailable, setIsAvailable] = useState<boolean>(false);
  const [data, setData] = useState<IUserBountyExtended[]>([]);
  const { data: bountyData, setData: setBountyData } = GetBountyListHook();
  const [unapprovedData, setUnapprovedData] = useState<IUserBountyExtended[]>([]);
  const [approvedData, setApprovedData] = useState<IUserBountyExtended[]>([]);
  const { data: seasonData } = GetSeasonListHook();
  const [totalOmc, setTotalOmc] = useState<number>(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!bountyData || bountyData.length === 0) {
          return;
        }

        const userBounties = await UserBountyService.getAllUserBounty();
        console.log('user bounties', userBounties);
        // Calculate total OMC
        const totalOmcValue = userBounties.reduce((total, userBounty) => total + userBounty.omc, 0);
        setTotalOmc(totalOmcValue);
        console.log('total OMC', totalOmcValue);

        /*const enrichedData = await Promise.all(userBounties.map(async (userBounty) => {
          
          const matchingBounty = bountyData.find(bounty => bounty._id === userBounty.bounty_id);
          // Throttle or delay API calls if needed
          await new Promise(resolve => setTimeout(resolve, 200)); // Delay to prevent too many concurrent requests

          const user = await EndUserService.getUserById(userBounty.userID);
          const discordID = user ? user.discordUserName : 'Unknown';
          const twitterID = user ? user.twitterID : 'Unknown';
          return {
            ...userBounty,
            bountyTitle: matchingBounty ? matchingBounty.title : 'Title not found',
            approved: userBounty.approved,
            discordID: discordID,
            twitterID: twitterID,
            bounty_id: userBounty.bounty_id 
          };
        }));*/

        const approvedData = userBounties.filter(item => item.approved);
        const unapprovedData = userBounties.filter(item => !item.approved);
        
        //const approvedData = enrichedData.filter(item => item.approved);
        //const unapprovedData = enrichedData.filter(item => !item.approved);
        setApprovedData(approvedData);
        setUnapprovedData(unapprovedData);
        setIsAvailable(true);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchData();
  }, [bountyData]);

  const removeBounty = async (item: IUserBountyExtended) => {
    try {
      if (item._id) {
        await UserBountyService.rejectuserBountyByID(item._id, item);
        const updatedData = unapprovedData.filter(bounty => bounty._id !== item._id);
        setUnapprovedData(updatedData);
      } else {
        console.error('Bounty ID is undefined');
      }
    } catch (error) {
      console.error('Error removing bounty:', error);
    }
  };

  const approveBounty = async (item: IUserBountyExtended) => {
    try {
      if (item._id) {
        await UserBountyService.acceptuserBountyByID(item._id, item);
        const updatedUnapprovedData = unapprovedData.filter(bounty => bounty._id !== item._id);
        setUnapprovedData(updatedUnapprovedData);
        const updatedApprovedData = [...approvedData, item];
        setApprovedData(updatedApprovedData);
      } else {
        console.error('Bounty ID is undefined');
      }
    } catch (error) {
      console.error('Error approving bounty:', error);
    }
  };

  return (
    <>
      <Nav />
      <ContentWrapper>
        <StyledSeason
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 1.5, ease: "easeInOut" }}
          key="bounty"
        >
          <A1>
            <div className="sections">
              <h4 className="item-title">User Submissions</h4>
              {isAvailable && (
                <table className="submissions-table">
                  <thead>
                    <tr>
                      <th>Image</th>
                      <th>Discord User Name</th>
                      <th>Twitter Handle</th>
                      <th>Bounty</th>
                      <th> Bounty ID </th>
                      <th>User Response</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {unapprovedData.map((item, index) => (
                      <tr key={index}>
                        <td>
                          {item.image && item.image.trim() !== "" && (
                            <img
                              src={item.image}
                              alt="Thumbnail"
                              onClick={() => window.open(item.image, "_blank")}
                            />
                          )}
                        </td>
                        <td>{item.discordID}</td>
                        <td>{item.twitterID}</td>
                        <td>{item.bountyTitle}</td>
                        <td>{item.bounty_id}</td>
                        <td>{item.desc}</td>
                        <td>
                          {/*<button onClick={() => removeBounty(item)}>Reject</button>*/}
                          <button onClick={() => approveBounty(item)}>Approve</button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
            <div className="sections">
              <h4 className="item-title">Approved Submissions</h4>
              {isAvailable && (
                <table className="submissions-table">
                  <thead>
                    <tr>
                      <th>Image</th>
                      <th>Discord User Name</th>
                      <th>Twitter Handle</th>
                      <th>Bounty</th>
                       <th> Bounty ID </th>
                      <th>User Response</th>
                    </tr>
                  </thead>
                  <tbody>
                    {approvedData.map((item, index) => (
                      <tr key={index}>
                        <td>
                          {item.image && item.image.trim() !== "" && (
                            <img
                              src={item.image}
                              alt="Thumbnail"
                              onClick={() => window.open(item.image, "_blank")}
                            />
                          )}
                        </td>
                        <td>{item.discordID}</td>
                        <td>{item.twitterID}</td>
                        <td>{item.bountyTitle}</td>
                        <td>{item.bounty_id}</td>
                        <td>{item.desc}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </A1>
        </StyledSeason>
      </ContentWrapper>
      <Footer />
    </>
  );
}

const ContentWrapper = styled.div`
  display: flex;
`;

const StyledCreateEvent = styled(motion.main)`
  background-image: url("/images/adminPostBg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;
  min-height: 100vh;
`;

const StyledSeason = styled(motion.main)`
  background-position: top left;
  min-height: 100vh;
  margin-top: 5.4rem;
  flex: 1;

  p {
    margin: 1.5rem 0;
    width: 90%;
    max-width: 100%;
    a {
      margin: 1.5rem 0;
      font-size: 0.7rem;
      width: 100%;
      color: #ba8c70;
      text-decoration: underline;
    }
  }
`;

const A1 = styled.section`
  width: 100%;
  padding: 6rem 0 3rem 3rem;

  @media (max-width: 768px) {
    padding: 1.5rem 0 1.5rem 1.5rem;
  }

  .sections {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0.5rem;

    h4 {
      font-weight: 800;
      font-size: 20px;
      line-height: 24px;
      text-transform: uppercase;
      margin-bottom: 1rem;
    }

    .submissions-table {
      width: 100%;
      border-collapse: collapse;

      th, td {
        border: 1px solid #ccc;
        padding: 0.5rem;
        text-align: left;
      }

      th {
        background-color: #000; /* Black background */
        color: #fff; /* White text */
      }

      td img {
        width: 50px;
        height: 50px;
        object-fit: cover;
        cursor: pointer;
      }

      button {
        margin-right: 0.5rem;
        padding: 0.25rem 0.5rem;
        font-size: 0.8rem;
        cursor: pointer;
      }
    }
  }
`;
