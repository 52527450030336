import axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios';
import { APIPath } from '../constant';

const onSuccess = (response: AxiosResponse) => response.data;

const onError = async (error: AxiosError) =>

Promise.reject({
    error: error?.response?.data,
    status: error?.response?.status,
});

class BaseService {
    private static client = axios.create({
        baseURL: APIPath.server,
    });

    private static request(config: AxiosRequestConfig) {
        return this.client(config).then(onSuccess).catch(onError);
    }

    static requestWithToken<T>(config: AxiosRequestConfig, token: string) {
        config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
        };
        return this.request(config);
    }

    private static requestPostDataWithToken<T>(config: AxiosRequestConfig, data: T, token: string) {
        config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
        };
        config.data = data; // Add the data to the config
        return this.request(config);
    }

    static getWithToken(url: string, token: string) {
        return this.requestWithToken({
        url,
        method: 'GET',
        }, token);
    }

    static postWithToken(url: string, token: string) {
        return this.requestWithToken({
        url,
        method: 'POST',
        }, token);
    }

    static postDataWithToken<T>(url: string, data: T, token: string) {
        return this.requestPostDataWithToken(
            {
            url,
            method: 'POST',
            data: {}, 
            },
            data,
            token
        );
    }

    static patchWithToken<T>(url: string, data: T, token: string) {
        return this.requestWithToken({
            url,
            method: 'PATCH',
            data,
            }, token);
    }

    static get(url: string, isSecure = true) {
        return this.request({
            url,
            method: 'GET',
        });
    }

    static post<T>(url: string, data: T, isSecure = true) {
        return this.request({
            url,
            method: 'POST',
            data,
        });
    }

    static put<T>(url: string, data: T, isSecure = true) {
        return this.request({
            url,
            method: 'PUT',
            data,
        });
    }

    static remove(url: string, isSecure = true) {
        return this.request({
            url,
            method: 'DELETE',
        });
    }

    static upload(url: string, data: any, header: Record<string, string>) {
        const client = axios({
            url,
            method: "PUT",
            headers: { ...header },
            data,
        });
        return client
        .then(onSuccess)
        .catch(onError);
    }
}

export { BaseService };
