import { BaseService } from './base'
import { APIPath, IQuest } from '../constant'

class Quest {
    create(data: IQuest): Promise<IQuest> {
        return BaseService.post(APIPath.quest, data)
    }

    getById(id: string): Promise<IQuest> {
        console.log('id in quest request', id);
        return BaseService.get(`${APIPath.quest}/${id}`)
    }

    getBySeasonId(id: string): Promise<IQuest> {
        return BaseService.get(`${APIPath.quest}/season/${id}`)
    }

    get(): Promise<IQuest[]> {
        return BaseService.get(`${APIPath.quest}`)
    }
    
}

const QuestService = new Quest()
Object.freeze(QuestService)
export { QuestService }